<template lang="">
  <IAmOverlay :loading="loading">
    <BCard>
      <div class="w-100 d-flex-between mb-1">
        <h3 class="mb-0">
          {{ $t('flight.agencyBalance.title') }}
        </h3>
        <b-button
          :variant="readonly ? 'primary' : 'outline-primary'"
          @click="handleEdit"
        >
          {{ readonly ? `${$t('edit')} ${$t('flight.agencyBalance.warningBalance')}` : $t('cancelEdit') }}
        </b-button>
      </div>
      <div style="width: 100%">
        <b-table
          bordered
          responsive
          class="mb-1 rounded w-100"
          thead-class="text-dark font-weight-bolder text-nowrap"
          :items="getAgencyBalance"
          :fields="tableColumns"
        >
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column})`]="data"
          >
            <div
              :key="column"
              class="text-dark text-center"
            >
              {{ $t(`flight.agencyBalance.${data.column}`) }}
            </div>
          </template>
          <template #cell(source)="{ item }">
            <div class="d-flex-center justify-content-start">
              <IAmLogoAirline
                v-if="['1S', 'QH', 'VU', 'VJ', 'VN1A', 'VN1A_MT', 'F1'].includes(item.source)"
                :airline="item.source.includes('VN1A') ? 'VN' : item.source"
                size="sm"
                custom-class="logo mr-50"
                :long-logo="true"
                custom-stlye="max-width: 100px"
              />
              {{ $te(`flight.airlines.${item.source}`) ? `${$t(`flight.airlines.${item.source}`)} (${item.source})` : item.source }}
            </div>
          </template>

          <template #cell(amount)="{ item }">
            <div
              class="font-weight-bolder text-right"
              :class="item.amount < item.warningBalance ? 'text-danger' : 'text-dark'"
            >
              {{ formatCurrency(item.amount) }}
            </div>
          </template>

          <template #cell(warningBalance)="{ item, index }">
            <div
              v-if="readonly"
              class="font-weight-bolder text-right flex-grow-1"
            >
              {{ formatCurrency(item.warningBalance) }}
            </div>
            <div
              v-else
              class="font-weight-bolder text-right"
            >
              <IAmInputMoney
                :value-money.sync="agencyWarningBalancesToEdit[index].warningBalance"
              />
            </div>
          </template>
        </b-table>

        <div
          v-if="!readonly"
          class="d-flex-center justify-content-lg-end"
        >
          <b-button
            variant="primary"
            @click="handleSave"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </BCard>
  </IAmOverlay>
</template>
<script>
import { ref } from '@vue/composition-api'
import { BCard, BTable, BButton } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'

import { apiAgencyBalanceConfigs } from '@/api/'

import { formatCurrency } from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  setup() {
    const { toastError, toastSuccess } = useToast()
    const getAgencyBalance = ref()
    const loading = ref(false)
    const tableColumns = ['source', 'amount', 'warningBalance']
    const readonly = ref(true)
    const agencyWarningBalancesToEdit = ref([])

    function fetchAgencyBalance() {
      loading.value = true
      return apiAgencyBalanceConfigs.getAgencyBalance()
        .then(res => {
          getAgencyBalance.value = res.data.balance
        })
        .catch(() => {
          toastError({
            title: 'flight.agencyBalance.fetchError',
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    fetchAgencyBalance()

    function handleEdit() {
      readonly.value = !readonly.value
      if (!readonly.value) {
        agencyWarningBalancesToEdit.value = cloneDeep(getAgencyBalance.value.map(({ id, warningBalance }) => ({
          id,
          warningBalance,
        })))
      } else {
        agencyWarningBalancesToEdit.value = []
      }
    }

    function handleSave() {
      loading.value = true

      apiAgencyBalanceConfigs.updateAgencyBalance({
        agencyBalances: agencyWarningBalancesToEdit.value,
      })
        .then(async () => {
          toastSuccess('Chỉnh sửa số dư cảnh báo thành công!')
          await fetchAgencyBalance()
          handleEdit()
        })
        .catch(error => {
          console.error({ error })
          toastError('Lỗi chỉnh sửa số dư cảnh báo')
        })
        .finally(() => {
          loading.value = false
        })
    }
    return {
      getAgencyBalance,
      loading,
      tableColumns,
      formatCurrency,
      handleEdit,
      handleSave,
      readonly,
      agencyWarningBalancesToEdit,
    }
  },
}
</script>
<style lang="">

</style>
