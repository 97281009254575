var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('BCard', [_c('div', {
    staticClass: "w-100 d-flex-between mb-1"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.agencyBalance.title')) + " ")]), _c('b-button', {
    attrs: {
      "variant": _vm.readonly ? 'primary' : 'outline-primary'
    },
    on: {
      "click": _vm.handleEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.readonly ? "".concat(_vm.$t('edit'), " ").concat(_vm.$t('flight.agencyBalance.warningBalance')) : _vm.$t('cancelEdit')) + " ")])], 1), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('b-table', {
    staticClass: "mb-1 rounded w-100",
    attrs: {
      "bordered": "",
      "responsive": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "items": _vm.getAgencyBalance,
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column,
            staticClass: "text-dark text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.agencyBalance.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(source)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start"
        }, [['1S', 'QH', 'VU', 'VJ', 'VN1A', 'VN1A_MT', 'F1'].includes(item.source) ? _c('IAmLogoAirline', {
          attrs: {
            "airline": item.source.includes('VN1A') ? 'VN' : item.source,
            "size": "sm",
            "custom-class": "logo mr-50",
            "long-logo": true,
            "custom-stlye": "max-width: 100px"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.source)) ? "".concat(_vm.$t("flight.airlines.".concat(item.source)), " (").concat(item.source, ")") : item.source) + " ")], 1)];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right",
          class: item.amount < item.warningBalance ? 'text-danger' : 'text-dark'
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.amount)) + " ")])];
      }
    }, {
      key: "cell(warningBalance)",
      fn: function fn(_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [_vm.readonly ? _c('div', {
          staticClass: "font-weight-bolder text-right flex-grow-1"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.warningBalance)) + " ")]) : _c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_c('IAmInputMoney', {
          attrs: {
            "value-money": _vm.agencyWarningBalancesToEdit[index].warningBalance
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              return _vm.$set(_vm.agencyWarningBalancesToEdit[index], "warningBalance", $event);
            },
            "update:value-money": function updateValueMoney($event) {
              return _vm.$set(_vm.agencyWarningBalancesToEdit[index], "warningBalance", $event);
            }
          }
        })], 1)];
      }
    }], null, true)
  }), !_vm.readonly ? _c('div', {
    staticClass: "d-flex-center justify-content-lg-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleSave
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])], 1) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }